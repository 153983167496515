import React, { useEffect, useState } from 'react';
import Timeline, {
  TimelineHeaders,
  CustomHeader
} from 'react-calendar-timeline';
import styles from './styles.module.scss';
import { convertDateToJapaneseFormat, FORMAT } from 'src/constants/datetime';
import clsx from 'clsx';
import { dayjs } from 'core-helpers';
import { useSelector } from 'react-redux';
import { RADIO_COLOR } from 'src/constants/common';
import { RootState } from '../../ScheduleDay/CustomTimeLine';
import scheduleSlice from 'src/redux/scheduleSlice';
import { IUser } from 'src/models/user';
import { AuthState } from 'src/redux/authInfoSlice';
import { useAppDispatch } from 'src/redux/hooks';
import { tabSchedule, TypeTabScheduleAction } from '../..';
interface CustomTimeLineProps {
  showDrawEdit: () => void;
  listEmployeeCharge: [{ id: number; title: string }];
  listScheduleData: any[];
  typeTabSchedule: TypeTabScheduleAction;
}

export interface TimelineWeekItem {
  id: string;
  group: string;
  start_time: number;
  end_time: number;
  title?: string;
  color?: string;
}

export interface TimelineWeekGroupItem {
  id: string;
  title: string;
}

const CustomTimeLine: React.FC<CustomTimeLineProps> = ({
  showDrawEdit,
  listEmployeeCharge,
  listScheduleData,
  typeTabSchedule
}) => {
  var keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start',
    itemTimeEndKey: 'end',
    groupLabelKey: 'title'
  };

  const { startTime, endTime } = useSelector(
    (state: RootState) => state.schedule
  );

  const auth: IUser | any = useSelector(
    (state: AuthState) => state?.auth?.user
  );

  const dispatch = useAppDispatch();

  const { setDetailSchedule } = scheduleSlice.actions;

  const [listScheduleWeek, setListScheduleWeek] =
    useState<TimelineWeekItem[]>(listScheduleData);

  const [listGroupResult, setListGroupResult] = useState<
    TimelineWeekGroupItem[]
  >([]);

  const listEmployee =
    listEmployeeCharge?.length > 0
      ? listEmployeeCharge
      : [{ id: auth?.id, title: auth?.name }];

  const listGroupCommon =
    listEmployeeCharge?.length > 0 ? listEmployeeCharge : [];

  useEffect(() => {
    if (Array.isArray(listScheduleData)) {
      const formattedData = listScheduleData.map((item) => ({
        ...item,
        color: item.color
      }));
      setListScheduleWeek(formattedData);
    }
  }, [listScheduleData, startTime, endTime]);

  useEffect(() => {
    const listGroup =
      typeTabSchedule?.includes(tabSchedule.Vehicle) ||
      typeTabSchedule?.includes(tabSchedule.Group) ||
      typeTabSchedule?.includes(tabSchedule.Participation)
        ? listGroupCommon
        : listEmployee;
    setListGroupResult(listGroup);
  }, [listScheduleData, typeTabSchedule]);

  return (
    <>
      <div className={styles.viewWeek}>
        {startTime &&
        endTime &&
        listGroupResult?.length > 0 &&
        listScheduleData?.length > 0 ? (
          <Timeline
            groups={listGroupResult}
            items={listScheduleWeek}
            keys={keys}
            stackItems
            sidebarWidth={120}
            visibleTimeStart={startTime}
            visibleTimeEnd={endTime}
            itemRenderer={({ item, getItemProps }) => {
              let bgColorCustom = RADIO_COLOR.find(
                (element: { value?: number }) =>
                  element.value === Number(item?.color ?? 1)
              )?.bgColor;
              let colorCustom = RADIO_COLOR.find(
                (element: { value?: number }) =>
                  element.value === Number(item?.color ?? 1)
              )?.color;

              return (
                <div
                  {...getItemProps({
                    style: {
                      background: bgColorCustom || '#0085FF',
                      color: colorCustom || 'white',
                      borderRadius: 8,
                      border: 'none',
                      fontWeight: '500'
                    },
                    className: clsx('', styles.customItemContent)
                  })}
                  onClick={() => {
                    dispatch(setDetailSchedule(item));
                    showDrawEdit();
                  }}
                >
                  <div className="rct-item-content">{item.title}</div>
                </div>
              );
            }}
            onTimeChange={(
              _start: number,
              _end: number,
              updateScrollCanvas
            ) => {
              if (_start > startTime.getTime() && _end < endTime.getTime())
                updateScrollCanvas(_start, _end);
            }}
            className="!bg-white"
          >
            <TimelineHeaders className="timeline h-[60px]">
              <CustomHeader unit="day">
                {({
                  headerContext: { intervals },
                  getRootProps,
                  getIntervalProps
                }: any) => (
                  <div {...getRootProps()}>
                    {intervals.map((interval: { startTime: Date }) => {
                      const day = dayjs(interval.startTime).format(
                        FORMAT.FORMAT_DATE_UPPER_B
                      );
                      return (
                        <div
                          {...getIntervalProps({
                            interval,
                            style: {
                              lineHeight: '30px',
                              textAlign: 'center',
                              borderLeft: '1px solid #e8e8e8',
                              cursor: 'pointer',
                              backgroundColor: '#ffffff',
                              whiteSpace: 'pre-wrap'
                            }
                          })}
                        >
                          {convertDateToJapaneseFormat(day)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </CustomHeader>
            </TimelineHeaders>
          </Timeline>
        ) : (
          <div>
            <Timeline
              groups={listGroupResult}
              items={listScheduleWeek}
              keys={keys}
              stackItems
              sidebarWidth={120}
              visibleTimeStart={startTime}
              visibleTimeEnd={endTime}
              onTimeChange={(
                _start: number,
                _end: number,
                updateScrollCanvas
              ) => {
                if (_start > startTime.getTime() && _end < endTime.getTime())
                  updateScrollCanvas(_start, _end);
              }}
              className="!bg-white"
            >
              <TimelineHeaders className="timeline h-[60px]">
                <CustomHeader unit="day">
                  {({
                    headerContext: { intervals },
                    getRootProps,
                    getIntervalProps
                  }: any) => (
                    <div {...getRootProps()}>
                      {intervals.map(
                        (interval: { startTime: Date }, index: number) => {
                          const day = dayjs(interval.startTime).format(
                            FORMAT.FORMAT_DATE_UPPER_B
                          );
                          return (
                            <div
                              {...getIntervalProps({
                                interval,
                                style: {
                                  lineHeight: '30px',
                                  textAlign: 'center',
                                  borderLeft: '1px solid #e8e8e8',
                                  cursor: 'pointer',
                                  backgroundColor: '#ffffff',
                                  whiteSpace: 'pre-wrap'
                                }
                              })}
                              key={index}
                            >
                              {convertDateToJapaneseFormat(day)}
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </CustomHeader>
              </TimelineHeaders>
            </Timeline>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomTimeLine;

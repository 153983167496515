import React from 'react';
import CustomTimeLine from './CustomTimeLine';
import 'react-calendar-timeline/lib/Timeline.css';
import CustomToolbarViewDayWeek from '../CustomToolbarViewDayWeek';
import { ScheduleEventI } from 'src/models/schedule';
import { TypeTabScheduleAction } from '..';

interface ScheduleDayProps {
  showDrawEdit: () => void;
  listEmployeeCharge: [{ id: number; title: string }];
  listScheduleData: ScheduleEventI[];
  typeTabSchedule: TypeTabScheduleAction;
}

const ScheduleDay: React.FC<ScheduleDayProps> = ({
  showDrawEdit,
  typeTabSchedule,
  listEmployeeCharge,
  listScheduleData
}) => {
  return (
    <div className="bg-white mt-5 mx-5 pt-5 mb-8">
      <CustomToolbarViewDayWeek />
      <CustomTimeLine
        showDrawEdit={showDrawEdit}
        listEmployeeCharge={listEmployeeCharge}
        listScheduleData={listScheduleData}
        typeTabSchedule={typeTabSchedule}
      />
    </div>
  );
};

export default ScheduleDay;
